























import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router/types/router'

import LessonVideoCurtain from '@/components/views/lesson/LessonVideoCurtain.vue'
import LessonCommonView from '@/components/views/lesson/LessonCommonView.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import CurrentTimeMixin from '@/mixins/CurrentTimeMixin'

@Component({
  components: {
    LessonCommonView,
    LessonVideoCurtain,
  },
})
export default class LessonsItem extends Mixins(MasterGroupMixin, PermissionsMixin, CurrentTimeMixin) {
  private prevRoute: null | Route = null

  private get lessonID () {
    return +this.$route.params.lessonID
  }

  private get breadcrumbs () {
    return [{
      name: 'Уроки',
      path: this.prevRoute && this.prevRoute.name === 'manager.education.masters.item.lessons'
        ? this.prevRoute.fullPath
        : '/manager/education/lessons',
    }]
  }

  private get lesson () {
    return MentorLessonsModule.lesson
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    next((vm: any) => {
      vm.prevRoute = from
    })
  }

  private mounted () {
    this.syncMasterGroup()
      .then(this.fetchLesson)
  }

  private fetchLesson() {
    MentorLessonsModule.fetchLesson({
      lessonId: this.lessonID,
      masterGroupId: this.currentMasterGroupID,
    })
      .catch((error) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.education.lessons' })
      })
  }

  private handleRedirectEdit() {
    this.$router.push({
      name: 'manager.education.lessons.item.edit',
      params: {
        groupID: this.currentMasterGroupID.toString(),
        lessonID: this.lessonID.toString(),
      },
    })
  }

  @Watch('lessonID')
  private watchLessonID() {
    this.fetchLesson()
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.lesson ? this.lesson.title : 'Урок',
    }
  }
}
